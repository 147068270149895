import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from "react";
import { PageLoader } from "components/PageLoader";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { AuthProvider } from "contexts/authContext";
import Navigation from "routes/Navigation";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}
const root = createRoot(document.getElementById("root")!);

root.render(
  <Suspense fallback={<PageLoader message="Loading..." showLogos />}>
    <BrowserRouter basename="/" >
        <AuthProvider>
          <Navigation />
        </AuthProvider>
    </BrowserRouter>
  </Suspense>
)

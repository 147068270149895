import { useState, useRef, useEffect, useCallback } from 'react';
import { HTTPResponse, asyncRequest } from 'api/fetch';
import { message } from 'antd';

export interface IUseFetch {
  data?: any;
  error: any | null;
  loading: boolean;
  status?: number;
  revalidate: () => Promise<void>;
}

export const useFetch = (url?: string, showError: boolean = false): IUseFetch => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const error = useRef<any | null>();
  const status = useRef<number>();

  const revalidate = useCallback(async () => {
    if (!url) return;
    setData(undefined);
    error.current = null;
    setLoading(prev => !prev);

    const response = await asyncRequest({
      url,
      method: 'GET',
      withCredentials: true
    });

    status.current = response.status;
    if (!response.isOk) {
      error.current = response.error;
      if (showError) {
        response.status === HTTPResponse.SERVER && message.error(response.error);
        response.status !== HTTPResponse.SERVER && message.info(response.error);
      }
    }
    if (response.isOk) {
      setData(response.result);
    }
    setLoading(prev => !prev);
  }, [showError, url]);

  useEffect(() => {
    if (!url) return;
    let cancel: boolean = false;
    const getDataAsync = async (): Promise<void> => {
      setData(undefined);
      error.current = null;
      setLoading(prev => !prev);
      const response = await asyncRequest({
        url,
        method: "GET",
        withCredentials: true
      });
      if (cancel) return;
      status.current = response.status;
      if (!response.isOk) {
        error.current = response.error;
        if (showError) {
          response.status === HTTPResponse.SERVER && message.error(response.error);
          response.status !== HTTPResponse.SERVER && message.info(response.error);
        }
      }
      if (response.isOk) {
        setData(response.result)
      }
      setLoading(prev => !prev);
    }
    getDataAsync();
    return () => {
      cancel = true;
    }
  }, [url, showError]);

  return {
    data,
    loading,
    error: error.current,
    status: status.current,
    revalidate
  }
}
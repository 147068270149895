import { HTTPResponse, IResponse, asyncRequest } from "api/fetch";
import { DEFAULT_MENU_STATES } from "constants/IUsuario";
import { useAuthUser } from "contexts/authContext";
import { IEmpresas, IMenusApp, ISelectedWork } from "interfaces/IUsuario";
import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";
import { showErrorsOnEntities } from "utils/HTTPMessages";

export const useApp = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedWork, setSelectedWork] = useState<ISelectedWork | null>();
    const [selectedClient, setSelectedClient] = useState<IEmpresas | null>();
    const [menusState, setMenuState] = useState<IMenusApp>(DEFAULT_MENU_STATES);
    const [modeMobile, setModeMobile] = useState<boolean>(false);
    const mounted = useRef<boolean>(false);
    const { ready, user } = useAuthUser();

    const synchronizeQuantification = useCallback(async () => {
        setLoading(true)
        const toastId = toast.loading("Synchronizing...");

        const response: IResponse = await asyncRequest({ url: `/api/obras/${selectedWork?.id}/pieces/assembly-types/proforma-quantification/sync`, data: {}, method: 'PUT', withCredentials: true })
        if (!response.isOk && response.status !== HTTPResponse.NOT_FOUND) {
            toast.update(toastId, { style: { display: "none", opacity: '0' } });
            showErrorsOnEntities(response.error, response.status)
            setLoading(false)
            return
        }

        toast.update(toastId, {
            render: "Synchronized",
            type: "success",
            isLoading: false,
            autoClose: 3000
        });

        setLoading(false)
    }, [selectedWork])

    return {
        loading,
        menusState,
        modeMobile,
        mounted,
        ready,
        selectedClient,
        selectedWork,
        user,
        setMenuState,
        setModeMobile,
        setSelectedClient,
        setSelectedWork,
        synchronizeQuantification,
    }
}
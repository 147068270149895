import { useAuth } from "App";
import { IEmpresas } from "interfaces/IUsuario";
import { useFetch } from 'hooks/useFetch';

interface IUseClientes {
  companys?: IEmpresas[];
  error: string | null;
  loading: boolean;
  onHandlerCompany: (company: IEmpresas, callback: VoidFunction) => void;
}

export const useClientes = (): IUseClientes => {
  const { onAssignOption } = useAuth();
  const { data: companys, error, loading } = useFetch(`/api/clientsEra/companies`);

  const onHandlerCompany = (company: IEmpresas, callback: VoidFunction): void => {
    onAssignOption(company, "Client");
    callback();
  }

  return {
    companys,
    error,
    loading,
    onHandlerCompany
  }
}
import { IResponse, httpClient } from "api/fetch";
import { AUTH_APIS } from "constants/APIS";

export interface IAuthService {

}

export class AuthService {

  constructor(
    private httpService: httpClient
  ) { }

  async logIn(username: string, password: string): Promise<IResponse> {
    return await this.httpService({
      url: AUTH_APIS.logIn,
      method: "POST",
      data: { username, password },
      withCredentials: true
    });
  }

  async signOut(): Promise<IResponse> {
    return await this.httpService({
      url: AUTH_APIS.logOut,
      method: "GET",
      withCredentials: true
    });
  }

  async getRoleWithPermissions(): Promise<IResponse> {
    return await this.httpService({
      url: AUTH_APIS.getPermissions,
      method: "GET",
      withCredentials: true
    });
  }

  async getActiveUser(): Promise<IResponse> {
    return await this.httpService({
      url: AUTH_APIS.getActiveUser,
      method: "GET",
      withCredentials: true
    })
  }
}
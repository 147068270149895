import { asyncRequest } from "api/fetch";
import { APIS } from "constants/APIS";
import { TNotification } from "interfaces/IUsuario";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import { useGetDataSWR } from "./useFetchSWR";

type Options = {
  modules: boolean;
  notifications: boolean;
};

export const useExtraActionsBox = () => {
  
  const [openOption, setOpenOption] = useState<Options>({ modules: false, notifications: false });

  const { data: notifications } = useGetDataSWR<TNotification[]>({
    url: '/api/notifications/by-user',
    keepCache: true,
    refresh: 5000
  });
  
  return {
    openOption,
    setOpenOption,
    notifications
  }
}

const updateNotifications = (data: TNotification[], id: number, checked: boolean) => {
  return data.map((notification: TNotification) => {
    if (notification.id === id) {
      return {
        ...notification,
        notification_read: checked
      }
    }
    return notification;
  })
}

export const useNotifications = (notifications: TNotification[]) => {

  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);
  const { mutate } = useSWRConfig();

  const notificationsToShow = useMemo(() => {
    if (!showOnlyUnread) return notifications;
    return notifications.filter(notification => !notification.notification_read)
  }, [showOnlyUnread, notifications]);

  const handlerOnNotificationRead = async (id: number, checked: boolean): Promise<void> => {

    mutate(APIS.notificationsByUser, (data: any) => updateNotifications(data, id, checked), { revalidate: false });

    const url = `${APIS.notifications}/${id}`;

    const response = await asyncRequest({
      url: checked ? `${url}/read` : `${url}/unread`,
      method: 'PATCH',
      withCredentials: true
    });

    if (response.isOk) return;

    mutate(APIS.notificationsByUser, (data: any) => updateNotifications(data, id, !checked), { revalidate: false });
    toast.error(response.error);
  }

  const handlerOnShowOnlyUnread = (show: boolean): void => setShowOnlyUnread(show);

  return {
    notificationsToShow,
    handlerOnNotificationRead,
    handlerOnShowOnlyUnread
  }

}
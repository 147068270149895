import { ReactElement } from 'react';
import './styles.less';

interface IProps {
  title?: string;
  subTitle?: string;
  children?: ReactElement | ReactElement[];
}
const SubHeader = ({ subTitle, title, children }: IProps) => {
  return (
    <nav className='sub-header'>
      <div>
        <p className='sub-title'>{subTitle ?? ""}</p>
        <p className='title'>{title ?? ""}</p>
      </div>
      {children}
    </nav>
  )
}

export default SubHeader
import { useCallback, useEffect, useState, createContext, ReactElement, useContext, useMemo } from 'react';
import { HTTPResponse, asyncRequest } from 'api/fetch';
import { IAction, userType } from 'interfaces/IUsuario';
import { message } from 'antd';
import { REDIRECT, UserStatus } from 'constants/IUsuario';
import { useLocation } from 'react-router-dom';
import { AuthService } from './service/AuthService';

interface IContextAuth {
  user: userType | null;
  wasLoggedOut: boolean;
  currentPagePermissions?: IAction;
  ready: UserStatus;
  signin: (username: string, password: string) => Promise<boolean>;
  signout: () => Promise<void>;
  verifyRedirect: () => void;
}
const AuthContext = createContext({} as IContextAuth);
const { Provider } = AuthContext;

interface IProps {
  children: ReactElement | ReactElement[];
}

export const AuthProvider = ({ children }: IProps) => {
  const [user, setUser] = useState<userType | null>(null);
  const [wasLoggedOut, setWasLoggedOut] = useState<boolean>(false);
  const [ready, setReady] = useState<UserStatus>(UserStatus.GetSession);

  const location = useLocation();
  const authService = useMemo(() => new AuthService(asyncRequest), []);

  const signin = useCallback(
    async (username: string, password: string): Promise<boolean> => {

      setWasLoggedOut(false);
      const userInformation = await authService.logIn(username, password);
      if (!userInformation.result) return false;

      setUser({ ...userInformation.result });
      return true;

    }, [authService]);

  const signout = useCallback(async (): Promise<void> => {
    setReady(UserStatus.SignOut);
    const response = await authService.signOut();

    if (!response.isOk) {
      response.status !== HTTPResponse.UNAUTHORIZED && message.error("An error ocurred while trying to log out");
      window.location.reload();
    }
    response.isOk && message.success("The session was closed!");
    // CLEAR DATA
    setWasLoggedOut(true);
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    setReady(UserStatus.Stop);
  }, [authService]);

  const verifyRedirect = (): void => {
    const existsRedirectURL: boolean = location.search.startsWith(REDIRECT);
    if (!existsRedirectURL) return;
    const urlRedirect = location.search.split(REDIRECT)[1];
    window.location.href = urlRedirect;
  }

  const currentPagePermissions = useMemo(() => {
    if (!user) return;
    const currentPath = location.pathname.toLocaleLowerCase();
    return user.role?.actions?.find(item => item.pathName?.toLocaleLowerCase() === currentPath);
  }, [user, location.pathname]);

  /*GET DATA FROM ACTIVE USER */
  useEffect(() => {
    if (wasLoggedOut) return;

    (async () => {
      setReady(UserStatus.GetSession);
      const sessionUser = await authService.getActiveUser();
      if (!sessionUser.isOk) {
        sessionUser.status !== HTTPResponse.UNAUTHORIZED && message.error("Error getting session");
        localStorage.clear();
        sessionStorage.clear();
        setUser(null);
      }
      if (sessionUser.isOk) {
        setUser({ ...sessionUser.result });
      }
      setReady(UserStatus.Stop);
    })();

  }, [wasLoggedOut, authService]);

  return (
    <Provider value={{ signin, signout, user, wasLoggedOut, ready, verifyRedirect, currentPagePermissions }}>
      {children}
    </Provider>
  )
}

export const useAuthUser = (): IContextAuth => {
  return useContext(AuthContext);
}

import { months } from 'constants/Reportes';
import CustomStore from 'devextreme/data/custom_store';

export const groupBy = function (xs: any[], key: string) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] ?? []).push(x);
        return rv;
    }, {});
};

export const groupByOnlyKey = (xs: any[], key: string): string[] => {
    return Object.keys(xs.reduce((rv, x) => {
        rv[x[key]] = rv[x[key]] ?? [];
        return rv;
    }, {}));
}

export const groupKeyByOrder = (xs: any[], key: string, orderBy: string): any[] => {
    const groupedData = groupBy(xs, orderBy);
    const groupedKeys: string[] = Object.keys(groupedData);
    const orderedKey: any[] = [];
    let maxLength: number = 0;

    groupedKeys.forEach((groupedKey) => {
        const array: any[] = groupedData[groupedKey];
        maxLength = Math.max(maxLength, array.length);
    });

    for (let i = 0; i < maxLength; i++) {
        groupedKeys.forEach((groupedKey) => {
            const element = groupedData[groupedKey][i];
            if (element) {
                const alreadyInArray = orderedKey.find((item) => item[key] === element[key]);
                if (!alreadyInArray) {
                    let totalKilos: number = 0;
                    let totalPiezas: number = 0;
                    const arrayFiltrado = xs.filter((item) => item[key] === element[key]);
                    arrayFiltrado.forEach((item) => {
                        totalKilos += item.Kilos;
                        totalPiezas += item.Piezas;
                    })
                    const data = {
                        [key]: element[key],
                        Kilos: totalKilos,
                        Piezas: totalPiezas
                    }
                    orderedKey.push(data);
                }
            }
        })
    }

    return orderedKey;

}

interface ISeries {
    value: string;
    name: string;
}
export const GenerateSeriesLineChart = (keysToAdd: string[], object?: object): ISeries[] => {
    if (!object) return [];
    const keys = Object.keys(object);
    let series: ISeries[] = [];
    keysToAdd.forEach((key) => {
        const serie = keys
            .filter((keys) => keys === key)
            .map((keys) => {
                return { value: keys, name: keys }
            })[0];
        series.push(serie);
    });
    return series;
}

export const createCustomData = (data: any[], key: string) => {
    return new CustomStore({
        loadMode: "raw",
        key,
        load: () => data
    });
}

export const getMinutesLeft = (start: Date, end: Date): number => {
    if (end.getMonth() > start.getMonth() ||
        end.getDate() > start.getDate()) return 0;
    const timeLeft: number = Math.round((end.getTime() - start.getTime()) / 60000);
    return timeLeft;
}


export const getTimeElapsed = (time: Date): string => {
    const now = new Date();

    if (now.getUTCFullYear() > time.getUTCFullYear() && time.getUTCMonth() - 1 <= now.getUTCMonth()) {
        const yearsElapsed = now.getUTCFullYear() - time.getUTCFullYear();
        return `${yearsElapsed} ${yearsElapsed > 1 ? "years" : "year"}`;
    }

    if (time.getMonth() === now.getMonth()) {
        return `${Math.abs(now.getDate() - time.getDate())} days ago`
    }

    const tempTime = new Date(time.getTime());
    tempTime.setMonth(time.getMonth() + 1);
    tempTime.setDate(0);

    const daysPast = Math.abs(new Date(now.getTime() - tempTime.getTime()).getDate()) + Math.abs(new Date(tempTime.getDate() - time.getDate()).getTime());

    if (daysPast >= tempTime.getDate()) {
        return `${months.at(time.getUTCMonth())} ${time.getDate()}`
    }

    return `${daysPast} days ago`
}
import { useState, useReducer } from "react";

const reducer = (state, action) => {
  if (action) {
    let newState = { ...state };
    newState[action.type] = { payload: action.payload, size: action.size };
    return newState;
  }
  return {};
};

const useFilesStateSize = () => {
  const [filesName, setFilesName] = useState([]);
  const [state, dispatch] = useReducer(reducer, {});

  const handleChange = (e) => {
    let names = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (file) {
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          let data = new Uint8Array(e.target.result);
          let arrayData = [];
          for (let point of data.values()) {
            arrayData.push(point);
          }
          dispatch({
            type: file.name,
            payload: arrayData,
            size: file.size,
          });
        };
        fileReader.readAsArrayBuffer(file);
        names.push(file.name);
      }
    }
    setFilesName(names);
  };

  const onSubmit = () => {
    dispatch();
    setFilesName([]);
  };

  return [filesName, state, handleChange, onSubmit];
};

export default useFilesStateSize;

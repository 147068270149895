import { lazy, LazyExoticComponent } from 'react';
import Works from 'pages/Home/Projects';
import Home from 'pages/Home/Home';
import Clientes from 'pages/Home/Clients';

export type JSXElement = () => JSX.Element;

export interface IRoute {
  path: string;
  to: string;
  Component: LazyExoticComponent<JSXElement> | JSXElement;
  name?: string;
  items?: IRoute[];
}
//ALL ROUTES PAGES
const StationMenu = lazy(() => import(/* webpackChunkName:"StationMenu" */'../pages/Stations/StationMenu'));
const BimModelsMenu = lazy(() => import(/* webpackChunkName:"BimModels" */'../pages/BimModels/BimModelsMenu'));
const CreateViewBim = lazy(() => import(/* webpackChunkName:"CreateViewBIM" */'../pages/BimModels/NewView'));
const EditViewBim = lazy(() => import(/* webpackChunkName:"EditViewBIM" */'../pages/BimModels/EditView'));
const ProcessEditor = lazy(() => import(/* webpackChunkName:"ProcessEditor" */'../pages/ProcessEditor/ProcessEditor'));
const PathEditor = lazy(() => import(/* webpackChunkName:"PathEditor" */'../pages/ProcessEditor/PathEditor'));
const ControlPlanosMenu = lazy(() => import(/* webpackChunkName:"MenuControlObras" */'../pages/ControlPlanos/MenuControlObras'));
const ControlPlanosIndex = lazy(() => import(/* webpackChunkName:"ControlPlanos" */'../pages/ControlPlanos/IndexControlPlanos'));
const GraficaPiezas = lazy(() => import(/* webpackChunkName:"GraficaPiezas" */'../pages/Reportes/Graficas'));
const GraficaObra = lazy(() => import(/* webpackChunkName:"GraficaPiezasObra" */'../pages/Reportes/GraficasObra'));
const GraficaHistorico = lazy(() => import(/* webpackChunkName:"GraficaHistorico" */'../pages/Reportes/GraficasHistorico'));
const GraficaChartsObras = lazy(() => import(/* webpackChunkName:"GraficaChartsObras" */'../pages/Reportes/GraficaChartsObras'));
const NewBimModel = lazy(() => import(/* webpackChunkName:"NewBimModel" */'../pages/BimModels/NewBimModel'));
const Roles = lazy(() => import(/* webpackChunkName:Roles */'../pages/Users/Roles'));
const Companies = lazy(() => import(/* webpackChunkName:Companies */'../pages/Companies/CompaniesMenu'));
const Projects = lazy(() => import(/* webpackChunkName:Projects */'../pages/Projects/Projects'));
const ClientsEra = lazy(() => import(/* webpackChunkName:ClientsEra */'../pages/ClientsEra/EraClients'));
const Providers = lazy(() => import(/* webpackChunkName:ClientsEra */'../pages/Providers/Providers'));
const Reports = lazy(() => import(/* webpackChunkName:"Reports" */'../pages/Reportes/Reportes'));
const GraficaPie = lazy(() => import(/* webpackChunkName:"GraficaPie" */'../pages/Reportes/GraficaPie'));
const MenuGantt = lazy(() => import(/* webpackChunkName:"GanttChart" */'../pages/GanttChart/Menu'));
const ManualGantt = lazy(() => import(/* webpackChunkName:"ManualGantt" */'../pages/GanttChart/Manual'));
const AutomaticGantt = lazy(() => import(/* webpackChunkName:"Automatic" */'../pages/GanttChart/Automatic'));
const GraficaProjects = lazy(() => import(/* webpackChunkName:"GraficaProjects" */'../pages/Reportes/GraficaProjects'));
const Users = lazy(() => import(/* webpackChunkName:"Users" */'../pages/Users/Users'));
const ShippmentChart = lazy(() => import(/* webpackChunkName:"ShippmentChart" */'../pages/Reportes/GraficaEmbarques'));
const GraficaPiesasPorDia = lazy(() => import(/*webpackChunkName:"GraficaPiesasPorDia"*/'../pages/Reportes/GraficaPiesasPorDia'));
const ProductionPanel = lazy(() => import(/* webpackChunkName:"ProductionPanel" */'../pages/ProductionPanel/ProductionPanel'));
const EraConcepts = lazy(() => import(/* webpackChunkName:"EraConcepts"*/'../pages/Concepts/EraConcepts'));
const ProjectManagement = lazy(() => import(/* webpackChunkName:"GanttChart" */'../pages/ProjectManagement/Menu'));
const contractIndex = lazy(() => import(/* webpackChunkName:"contractIndex" */'../pages/Concepts/ContractIndex'));
const ProformaEnsambleIndex = lazy(() => import(/* webpackChunkName:"ProformaEnsambleIndex" */'../pages/ProjectManagement/ProformaEnsamble/Index'));
const AssignEnsamble = lazy(() => import(/* webpackChunkName:"AssignEnsamble" */'../pages/ProjectManagement/ProformaEnsamble/AssignEnsamble'));

// Sicicc Modules
const SiciccIndex = lazy(() => import(/* webpackChunkName:"SiciccIndex */'../pages/Sicicc/Index'));
const SiciccProject = lazy(() => import(/* webpackChunkName:"SiciccProjects */'../pages/Sicicc/Projects'));
const SiciccHome = lazy(() => import(/* webpackChunkName:"SiciccHome */'../pages/Sicicc/Home'));
const SiciccContract = lazy(() => import(/* webpackChunkName:"SiciccContracts */'../pages/Sicicc/Contratos/Index'));
const SiciccContractForm = lazy(() => import(/* webpackChunkName:"SiciccContractForms */'../pages/Sicicc/Contratos/Form'));
const SiciccCatalog = lazy(() => import(/* webpackChunkName:"SiciccCatalogs */'../pages/Sicicc/Catalogs/Index'));
const SiciccContractShow = lazy(() => import(/* webpackChunkName:"SiciccContractShow */'../pages/Sicicc/Contratos/Contrato'));
const SiciccHistoryContract = lazy(() => import(/* webpackChunkName:"SiciccHistoryContract */'../pages/Sicicc/Contratos/History/RecordContractInfo'));
const SiciccHistoryIndex = lazy(() => import(/* webpackChunkName:"SiciccHistoryIndex */'../pages/Sicicc/Contratos/History/Index'));
//  Proforma
const Proforma = lazy(() => import(/* webpackChunkName: Proforma*/'../pages/ProjectManagement/Proforma/Index'));
const ProformaShow = lazy(() => import(/* webpackChunkName: ProformaShow*/'../pages/ProjectManagement/Proforma/Proforma'));
const SiciccProformaChangeOrder = lazy(() => import(/* webpackChunkName: SiciccProformaChangeOrder*/'../pages/Sicicc/Proforma/ChangeOrder'));
const SiciccProformaRecord = lazy(() => import(/* webpackChunkName: SiciccProformaRecord*/'../pages/Sicicc/Proforma/components/RecordProforma'));
const SiciccProformaRecordShow = lazy(() => import(/* webpackChunkName: SiciccProformaRecordShow*/'../pages/Sicicc/Proforma/components/ShowRecord'));
// Materials
const ProviderAgreement = lazy(() => import(/* webpackChunkName: MaterialsIndex*/'../pages/ProjectManagement/ProviderAgreement/ShowAgremments'));
const ProviderAgreementShow = lazy(() => import(/* webpackChunkName: MaterialShow*/'../pages/ProjectManagement/ProviderAgreement/ProviderAgreement'));
const AgreementPerProvider = lazy(() => import(/* webpackChunkName: MaterialShow*/'../pages/ProjectManagement/ProviderAgreement/Components/AgreemetPerProvider'));
const Agreement = lazy(() => import(/* webpackChunkName: MaterialShow*/'../pages/ProjectManagement/ProviderAgreement/Components/Agreement'));
const AgreementRecord = lazy(() => import(/* webpackChunkName: MaterialShow*/'../pages/ProjectManagement/ProviderAgreement/Components/AgreementRecord'));
// Subcontratista
const AccountStatementIndex = lazy(() => import(/* webpackChunkName: AccountStatement*/'../pages/ProjectManagement/AccountStatement/Index'));
const AccountStatementShow = lazy(() => import(/* webpackChunkName: AccountStatement*/'../pages/ProjectManagement/AccountStatement/AccountStatement'));
// const SubcontractorAgreement = lazy(() => import(/* webpackChunkName: SubcontractorAgreement*/'../pages/ProjectManagement/SubcontractorAgreement/SubcontractorAgreement'));
// const AuthorizeSubcontractorAgreement = lazy(() => import(/* webpackChunkName: SubcontractorAgreement*/'../pages/ProjectManagement/SubcontractorAgreement/Components/AuthorizeAgreement'));

const SiciccSubcontratista = lazy(() => import(/* webpackChunkName: SiciccSubContratista */ '../pages/Sicicc/Subcontratista/Index'))
const SiciccSubcontratistaAgreement = lazy(() => import(/* webpackChunkName: SiciccSubcontratistaAgreement */ '../pages/Sicicc/Subcontratista/Agreements'));
const SiciccSubcontratistaAgreementForm = lazy(() => import(/* webpackChunkName: AuContratistaAgreementForm */ '../pages/Sicicc/Subcontratista/FormAgreement'));
const SiciccSubcontratistaAgreementDetails = lazy(() => import(/* webpackChunkName: SiciccSubcontratistaAgreementDetails */ '../pages/Sicicc/Subcontratista/Details'));
const SiciccSubcontratistaAgreementHome = lazy(() => import(/* webpackChunkName: SiciccSubcontratistaAgreementHome */ '../pages/Sicicc/Subcontratista/Home'));
const SiciccSubcontratistaAgreementEdit = lazy(() => import(/* webpackChunkName: SiciccSubcontratistaAgreementHome */ '../pages/Sicicc/Subcontratista/EditAgreement'));
const SiciccSubContratistaAgreementHistory = lazy(() => import(/* webpackChunkName: SiciccSubcontratistaAgreementHistory  */ '../pages/Sicicc/Subcontratista/History/OrderChangeConfirm'));
const SiciccSubContratistaAgreementHistoryIndex = lazy(() => import(/* webpackChunkName: SiciccSubcontratistaAgreementHistory  */ '../pages/Sicicc/Subcontratista/History/Index'));
// Estimaciones 
const SiciccEstimationsIndex = lazy(() => import(/* webpackChunkName: SiciccEstimationsIndex */ '../pages/Sicicc/Estimaciones/Index'));
const SiciccEstimationIndexAgreements = lazy(() => import(/* webpackChunkName: SiciccEstimationIndexContract */ '../pages/Sicicc/Estimaciones/IndexAgreements'));
const SiciccEstimationIndexContract = lazy(() => import(/* webpackChunkName: SiciccEstimationIndexContract */ '../pages/Sicicc/Estimaciones/IndexContract'));
const siciccEstimationEstimate = lazy(() => import(/* webpackChunkName: SiciccEstimation */ '../pages/Sicicc/Estimaciones/Estimates'));
// const siciccEstimationForm = lazy(() => import(/* webpackChunkName: SiciccEstimation */ '../pages/Sicicc/Estimaciones/Components/FormToEstimacion'));

const UnitPriceMatriz = lazy(() => import(/* webpackChunkName:"UnitPriceMatriz"*/ '../pages/Concepts/UnitPriceMatriz'));
const Consumables = lazy(() => import(/* webpackChunkName:"Consumables"*/ '../pages/Concepts/Consumables'));
const LinkConcept = lazy(() => import(/* webpackChunkName:"LinkConcepto"*/ '../pages/Concepts/LinkConcept'));
const ConceptKeys = lazy(() => import(/* webPackChunkName:"ConceptKeys"*/ '../pages/Concepts/ConceptKeys'));
const UnitPriceExcel = lazy(() => import(/* webpackChunkName:"UnitPriceExcel"*/ '../pages/Concepts/UniPriceExcel'));
const UploadUnitPriceExcel = lazy(() => import(/* webpackChunkName:"UploadUnitPriceExcel"*/ '../pages/Concepts/UploadCatalogByExcel'));
const AssemblyTypes = lazy(() => import(/* webpackChunkName:"AssemblyTypes"*/ '../pages/Concepts/AssemblyTypes'));
const AddAssemblyTypes = lazy(() => import(/* webpackChunkName:"AddAssemblyTypes"*/'../pages/Concepts/AddAssemblyType'));
const EditAssemblyTypes = lazy(() => import(/* webpackChunkName:"EditAssemblyTypes"*/'../pages/Concepts/EditAssemblyTypes'));
const AssemblyIndex = lazy(() => import(/* webpackChunkName:"EditAssemblyTypes"*/'../pages/Assembly/Index'));
const SyncERP = lazy(() => import(/* webpackChunkName:"SyncERP"*/'../pages/SyncERP/Menu'));
const BIMMenu = lazy(() => import(/* webpackChunkName:"BIMModelsMenu"*/'../pages/BimModels/Menu'));
const AccessMenu = lazy(() => import(/* webpackChunkName:"UserMenu"*/'../pages/Users/Menu'));
const ConceptsMenu = lazy(() => import(/* webpackChunkName:"ConceptsMenu"*/'../pages/Concepts/Menu'));
const CatalogsMenu = lazy(() => import(/* webpackChunkName:"CatalogsMenu"*/'../pages/Catalogs_Menu'));
const AssemblyMenu = lazy(() => import(/* webpackChunkName:"AssemblyMenu"*/'../pages/Assembly/Menu'));

// PURCHASE ORDERS
const PurchaseOrderIndexProviders = lazy(() => import(/*webpackChunkName: PurchaseOrderIndexProviders */ '../pages/ProjectManagement/PurchaseOrder/IndexProviders'));
const PurchaseOrderIndexOption = lazy(() => import(/* webpackChunkName: PurchaseOrderIndexAgreements */ '../pages/ProjectManagement/PurchaseOrder/IndexOptionsPurchase'));
const PurchaseOrderIndexAgreements = lazy(() => import(/* webpackChunkName: PurchaseOrderIndexAgreements */ '../pages/ProjectManagement/PurchaseOrder/IndexAgreements'));
// const PurchaseOrderForm = lazy(() => import(/* webpackChunkName: PurchaseOrderIndexAgreements */ '../pages/ProjectManagement/PurchaseOrder/FormPurchase'));
const PurchaseOrderconcepts = lazy(() => import(/* webpackChunkName: PurchaseOrderIndexAgreements */ '../pages/ProjectManagement/PurchaseOrder/SelectConcepts'));
const ListOrders = lazy(() => import(/* webpackChunkName: PurchaseOrderIndexAgreements */ '../pages/ProjectManagement/PurchaseOrder/ListOrders'));
const ShowOrder = lazy(() => import(/* webpackChunkName: PurchaseOrderIndexAgreements */ '../pages/ProjectManagement/PurchaseOrder/ShowOrder'));

//ROUTE PATHS 
export const ROUTES_PATH = {
  UNITPRICE_EXCEL: '/projectmanagement/contracts/view',
  UNITPIRCE_UPLOAD_EXCEL: '/projectmanagement/contracts/upload',
  ASSEMBLY_TYPES: '/assemblies/bim',
  ADD_ASSEMBLY_TYPES: '/assemblies/bim/add',
  EDIT_ASSEMBLY_TYPES: '/concepts/assemblyTypes/:id/project/:projectId',
  ERA_CONCEPTS: '/concepts/era',
  PROFORMA_ENSAMBLE: '/projectmanagement/proforma/ensamble',
  PRFORMA: '/projectmanagement/proforma',
  PROVIDER_AGREEMENT: '/projectmanagement/provider/agreement',
  ASSEMBLY: '/assemblies/proformas',
  SYNC_MENU: '/syncERP',
  ASSEMBLIES_MENU: '/assemblies',
  BIM_MODELS: '/bim',
  VIEW_BIM_MODELS: '/bim/models',
  NEW_BIM_MODEL: '/bim/new',
  NEW_VIEW_BIM_MODEL: '/bim/view',
  EDIT_VIEW_BIM_MODEL: '/bim/editView',
  CLIENTS: '/clients',
  ROLES: '/access/roles',
  USERS: '/access/users',
  ACCESS_MENU: '/access',
  CONCEPTS_MENU: '/concepts',
  CONCEPTS_KEYS: '/concepts/keys',
  CATALOGS_MENU: '/catalogs',
  HOME: '/home',
  CATALOGS_PROJECTS: '/catalogs/projects',
  CATALOGS_COMPANIES: '/catalogs/companies',
  CATALOGS_CLIENTERA: '/catalogs/clientsEra',
  CATALOGS_PROVIDER: '/catalogs/providers',
  PURCHASE_ORDER: '/projectmanagement/purchaseorder',
} as const;

export const CLIENTS_ROUTE: IRoute = {
  path: ROUTES_PATH.CLIENTS,
  to: ROUTES_PATH.CLIENTS,
  Component: Clientes
}
export const HOME_ROUTE: IRoute = {
  path: ROUTES_PATH.HOME,
  to: ROUTES_PATH.HOME,
  Component: Home
}
//PROJECT ROUTES
export const routes: IRoute[] = [
  CLIENTS_ROUTE,
  HOME_ROUTE,
  {
    path: ROUTES_PATH.CONCEPTS_MENU,
    to: ROUTES_PATH.CONCEPTS_MENU,
    Component: ConceptsMenu
  },
  {
    path: ROUTES_PATH.ASSEMBLIES_MENU,
    to: ROUTES_PATH.ASSEMBLIES_MENU,
    Component: AssemblyMenu
  },
  {
    path: ROUTES_PATH.CATALOGS_MENU,
    to: ROUTES_PATH.CATALOGS_MENU,
    Component: CatalogsMenu
  },
  {
    path: "/projects",
    to: "/projects",
    Component: Works
  },
  {
    path: "/management/stations",
    to: "/management/stations",
    Component: StationMenu,
    name: "Stations"
  },
  {
    path: ROUTES_PATH.BIM_MODELS,
    to: ROUTES_PATH.BIM_MODELS,
    Component: BIMMenu
  },
  {
    path: "/bim/models",
    to: "bim/models",
    Component: BimModelsMenu
  },
  {
    path: "/bim/new",
    to: "bim/new",
    Component: NewBimModel,
    // name: "New Bim"
  },
  {
    path: "/bim/view",
    to: "bim/view",
    Component: CreateViewBim,
    // name: "Create View"
  },
  {
    path: "/bim/editView",
    to: "/bim/editView",
    Component: EditViewBim
  },
  {
    path: ROUTES_PATH.ACCESS_MENU,
    to: ROUTES_PATH.ACCESS_MENU,
    Component: AccessMenu
  },
  {
    path: "processEditor",
    to: "processEditor",
    Component: ProcessEditor,
    name: "Paths"
  },
  {
    path: "processEditor/:id",
    to: "processEditor/:id",
    Component: PathEditor,
    name: "Path/Routes"
  },
  {
    path: "DrawingManagement/Index/:id",
    to: "DrawingManagement/Index/:id",
    Component: ControlPlanosIndex,
  },
  {
    path: "DrawingManagement",
    to: "DrawingManagement",
    Component: ControlPlanosMenu,
  },
  {
    path: "projectreports",
    to: "projectreports",
    Component: Reports
  },
  {
    path: "projectreports/pieces/project",
    to: "projectreports/pieces/project",
    Component: GraficaObra
  },
  {
    path: "projectreports/pieces/record",
    to: "projectreports/pieces/record",
    Component: GraficaHistorico
  },
  {
    path: "reports/pieces",
    to: "reports/pieces",
    Component: GraficaPiezas
  },
  {
    path: "reports/obras",
    to: "reports/obras",
    Component: GraficaChartsObras
  },
  {
    path: "reports/pie/chart",
    to: "reports/pie/chart",
    Component: GraficaPie
  },
  {
    path: "gantt",
    to: "gantt",
    Component: MenuGantt,
    name: "Gantt"
  },
  {
    path: "gantt/manual",
    to: "gantt/manual",
    Component: ManualGantt
  },
  {
    path: "gantt/automatic",
    to: "gantt/automatic",
    Component: AutomaticGantt
  },
  // Contract new routes
  {
    path: "/projectmanagement",
    to: "/projectmanagement",
    Component: ProjectManagement,
    name: "Project Management"
  },
  {
    path: "/projectmanagement/contracts",
    to: "/projectmanagement/contracts",
    Component: contractIndex,
  },
  {
    path: ROUTES_PATH.ROLES,
    to: ROUTES_PATH.ROLES,
    Component: Roles
  },
  {
    path: ROUTES_PATH.CATALOGS_COMPANIES,
    to: ROUTES_PATH.CATALOGS_COMPANIES,
    Component: Companies
  },
  {
    path: ROUTES_PATH.CATALOGS_PROJECTS,
    to: ROUTES_PATH.CATALOGS_PROJECTS,
    Component: Projects
  },
  {
    path: ROUTES_PATH.CATALOGS_CLIENTERA,
    to: ROUTES_PATH.CATALOGS_CLIENTERA,
    Component: ClientsEra
  },
  {
    path: ROUTES_PATH.CATALOGS_PROVIDER,
    to: ROUTES_PATH.CATALOGS_PROVIDER,
    Component: Providers
  },
  {
    path: "reports/chart/projects",
    to: "reports/chart/projects",
    Component: GraficaProjects
  },
  {
    path: ROUTES_PATH.USERS,
    to: ROUTES_PATH.USERS,
    Component: Users
  },
  {
    path: "/reports/Shipments",
    to: "/reports/Shipments",
    Component: ShippmentChart
  },
  {
    path: "/reports/PiecesPerDay",
    to: "/reports/PiecesPerDay",
    Component: GraficaPiesasPorDia
  },
  {
    path: '/productionPanel',
    to: '/productionPanel',
    Component: ProductionPanel
  },
  {
    path: "/sicicc",
    to: "/sicicc",
    Component: SiciccIndex
  },
  {
    path: "/sicicc/Projects",
    to: "/sicicc/Projects",
    Component: SiciccProject
  },
  {
    path: "/sicicc/Home",
    to: "/sicicc/Home",
    Component: SiciccHome
  },
  {
    path: "/sicicc/Contract/Menu",
    to: "/Sicicc/Contract/Menu",
    Component: SiciccContract
  },
  {
    path: "/sicicc/Contract/form",
    to: "/Sicicc/Contract/form",
    Component: SiciccContractForm
  },
  {
    path: "/sicicc/Contract/Show",
    to: "/Sicicc/Contract/Show",
    Component: SiciccContractShow
  },
  {
    path: "/sicicc/Catalogs",
    to: "/sicicc/Catalogs",
    Component: SiciccCatalog
  },
  {
    path: "/sicicc/Catalogs/:tab",
    to: "/sicicc/Catalogs/:tab",
    Component: SiciccCatalog
  },
  {
    path: "/sicicc/Contract/history",
    to: "/sicicc/Contract/history",
    Component: SiciccHistoryContract
  },
  {
    path: "/sicicc/Contract/history/index",
    to: "/sicicc/Contract/history/index",
    Component: SiciccHistoryIndex
  },
  // proforma routes
  {
    path: `${ROUTES_PATH.PRFORMA}`,
    to: `${ROUTES_PATH.PRFORMA}`,
    Component: Proforma
  },
  {
    path: `${ROUTES_PATH.PRFORMA}/show`,
    to: `${ROUTES_PATH.PRFORMA}/show`,
    Component: ProformaShow
  },
  {
    path: "/sicicc/Proforma/ChangeOrder",
    to: "/sicicc/Proforma/ChangeOrder",
    Component: SiciccProformaChangeOrder
  },
  {
    path: "/sicicc/Proforma/Record",
    to: "/sicicc/Proforma/Record",
    Component: SiciccProformaRecord
  },
  {
    path: "/sicicc/Proforma/Record/Show",
    to: "/sicicc/Proforma/Record/Show",
    Component: SiciccProformaRecordShow
  },
  // Subcontratistas routes
  {
    path: "/sicicc/Subcontratista/menu",
    to: "/sicicc/Subcontratista/menu",
    Component: SiciccSubcontratista
  },
  {
    path: "/Sicicc/Subcontratista/Agreements",
    to: "/Sicicc/Subcontratista/Agreements",
    Component: SiciccSubcontratistaAgreement
  },
  {
    path: "/Sicicc/Subcontratista/Agreements/form",
    to: "/Sicicc/Subcontratista/Agreements/form",
    Component: SiciccSubcontratistaAgreementForm
  }, {
    path: "/Sicicc/Subcontratista/Agreements/details",
    to: "/Sicicc/Subcontratista/Agreements/details",
    Component: SiciccSubcontratistaAgreementDetails
  },
  {
    path: "/Sicicc/Subcontratista/Agreements/home",
    to: "/Sicicc/Subcontratista/Agreements/home",
    Component: SiciccSubcontratistaAgreementHome
  },
  {
    path: "/Sicicc/Subcontratista/Agreements/Edit",
    to: "/Sicicc/Subcontratista/Agreements/Edit",
    Component: SiciccSubcontratistaAgreementEdit,
  },
  {
    path: "/Sicicc/Subcontratista/Agreements/OrderChange",
    to: "/Sicicc/Subcontratista/Agreements/OrderChange",
    Component: SiciccSubContratistaAgreementHistory
  },
  {
    path: "/Sicicc/Subcontratista/Agreements/Index",
    to: "/Sicicc/Subcontratista/Agreements/Index",
    Component: SiciccSubContratistaAgreementHistoryIndex
  },
  // Agreement Materials
  {
    path: `/projectmanagement/provider/agreement`,
    to: `/projectmanagement/provider/agreement`,
    Component: ProviderAgreement
  },
  {
    path: `${ROUTES_PATH.PROVIDER_AGREEMENT}/create`,
    to: `${ROUTES_PATH.PROVIDER_AGREEMENT}/create`,
    Component: ProviderAgreementShow
  },
  {
    path: `${ROUTES_PATH.PROVIDER_AGREEMENT}/list`,
    to: `${ROUTES_PATH.PROVIDER_AGREEMENT}/list`,
    Component: AgreementPerProvider
  },
  {
    path: `${ROUTES_PATH.PROVIDER_AGREEMENT}/list/authorize`,
    to: `${ROUTES_PATH.PROVIDER_AGREEMENT}/list/authorize`,
    Component: Agreement
  },
  {
    path: `${ROUTES_PATH.PROVIDER_AGREEMENT}/record`,
    to: `${ROUTES_PATH.PROVIDER_AGREEMENT}/record`,
    Component: AgreementRecord
  },
  {
    path: `/projectmanagement/account/statements`,
    to: `/projectmanagement/account/statements`,
    Component: AccountStatementIndex
  },
  {
    path: `/projectmanagement/account/statements/show`,
    to: `/projectmanagement/account/statements/show`,
    Component: AccountStatementShow
  },
  {
    path: '/concepts/unitPrice',
    to: '/concepts/unitPrice',
    Component: UnitPriceMatriz
  },
  {
    path: '/concepts/unitPrice/:id/consumables',
    to: '/concepts/unitPrice/:id/consumables',
    Component: Consumables
  },
  {
    path: '/concepts/link',
    to: '/concepts/link',
    Component: LinkConcept
  },
  {
    path: ROUTES_PATH.CONCEPTS_KEYS,
    to: ROUTES_PATH.CONCEPTS_KEYS,
    Component: ConceptKeys
  },
  {
    path: ROUTES_PATH.UNITPRICE_EXCEL,
    to: ROUTES_PATH.UNITPRICE_EXCEL,
    Component: UnitPriceExcel,
  },
  {
    path: ROUTES_PATH.UNITPIRCE_UPLOAD_EXCEL,
    to: ROUTES_PATH.UNITPIRCE_UPLOAD_EXCEL,
    Component: UploadUnitPriceExcel
  },
  {
    path: ROUTES_PATH.ASSEMBLY_TYPES,
    to: ROUTES_PATH.ASSEMBLY_TYPES,
    Component: AssemblyTypes
  },
  {
    path: ROUTES_PATH.ADD_ASSEMBLY_TYPES,
    to: ROUTES_PATH.ADD_ASSEMBLY_TYPES,
    Component: AddAssemblyTypes
  },
  {
    path: ROUTES_PATH.EDIT_ASSEMBLY_TYPES,
    to: ROUTES_PATH.EDIT_ASSEMBLY_TYPES,
    Component: EditAssemblyTypes
  },
  {
    path: ROUTES_PATH.ERA_CONCEPTS,
    to: ROUTES_PATH.ERA_CONCEPTS,
    Component: EraConcepts
  },
  {
    path: ROUTES_PATH.PROFORMA_ENSAMBLE,
    to: ROUTES_PATH.PROFORMA_ENSAMBLE,
    Component: ProformaEnsambleIndex
  },
  {
    path: `${ROUTES_PATH.PROFORMA_ENSAMBLE}/assign`,
    to: `${ROUTES_PATH.PROFORMA_ENSAMBLE}/assign`,
    Component: AssignEnsamble
  },
  {
    path: ROUTES_PATH.ASSEMBLY,
    to: ROUTES_PATH.ASSEMBLY,
    Component: AssemblyIndex
  },
  {
    path: ROUTES_PATH.SYNC_MENU,
    to: ROUTES_PATH.SYNC_MENU,
    Component: SyncERP
  },
  {
    path: ROUTES_PATH.PURCHASE_ORDER,
    to: ROUTES_PATH.PURCHASE_ORDER,
    Component: PurchaseOrderIndexProviders
  },
  {
    path: `${ROUTES_PATH.PURCHASE_ORDER}/option`,
    to: `${ROUTES_PATH.PURCHASE_ORDER}/option`,
    Component: PurchaseOrderIndexOption
  },
  {
    path: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements`,
    to: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements`,
    Component: PurchaseOrderIndexAgreements
  },
  {
    path: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements/PurchaseOrder/Form`,
    to: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements/PurchaseOrder/Form`,
    Component: PurchaseOrderconcepts
  },
  {
    path: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements/PurchaseOrder/List`,
    to: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements/PurchaseOrder/List`,
    Component: ListOrders
  },
  {
    path: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements/PurchaseOrder/List/Authorize`,
    to: `${ROUTES_PATH.PURCHASE_ORDER}/Option/Agreements/PurchaseOrder/List/Authorize`,
    Component: ShowOrder
  }
]


import { ReactElement, memo, useMemo } from 'react'
import { TNotification } from 'interfaces/IUsuario';
import { Switch } from 'antd';
import { getTimeElapsed } from 'utils/Functions';
import { CheckBox } from 'devextreme-react';
import { useExtraActionsBox, useNotifications } from 'hooks/useNotifications';
import { PRIORITY } from 'constants/Notifications';
import { IconBIM } from './Icons';
import { useNavigate } from 'react-router-dom';
import { BookOutlined, BranchesOutlined, CloudDownloadOutlined, SyncOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import { ROUTES_PATH } from 'routes/routes';
import { useModulesMenu } from './hooks/useExtraActionsBox';
import './TopBar.less';
import './styles.less'

interface Props {
  children?: ReactElement | ReactElement[];
}

const ExtraActionsBox = ({ children }: Props) => {

  const { notifications, openOption, setOpenOption } = useExtraActionsBox();

  return (
    <>
      <div
        className='box-extra-options-container'
      >
        <i
          className='fa-regular fa-bell'
          style={{ transform: openOption.notifications ? 'rotate(15deg)' : 'none' }}
          onClick={() => setOpenOption(prev => ({ ...prev, notifications: !prev.notifications }))}
        >
          <span><p>{notifications?.filter(notification => !notification.notification_read).length ?? 0}</p></span>
        </i>
        <i
          className='fa solid fa-layer-group'
          style={{ transform: openOption.modules ? 'rotate(15deg)' : 'none' }}
          onClick={() => setOpenOption(prev => ({ ...prev, modules: !prev.modules }))}
        >
        </i>
      </div>
      {children}
      <NotificationsList
        notifications={notifications ?? []}
        visible={openOption.notifications}
      />
      <ModulesMenu
        visible={openOption.modules}
      />
    </>
  )
}

interface INotificationsList {
  notifications: TNotification[];
  visible?: boolean;
}
const NotificationsList = ({ notifications, visible = false }: INotificationsList) => {

  const { handlerOnNotificationRead, handlerOnShowOnlyUnread, notificationsToShow } = useNotifications(notifications);

  return (
    <section
      className='notification-floating-container'
      style={{ display: visible ? 'block' : ' none' }}
    >
      <header>
        <h4>Notifications</h4>
        <p className='show-unread-notifications'>Only show unread
          <Switch size='small' onChange={(checked) => handlerOnShowOnlyUnread(checked)} />
        </p>
      </header>
      <div className='notification-list'>
        {notificationsToShow.map(notification => (
          <NotificationListItem
            key={notification.id}
            handlerOnNotificationRead={handlerOnNotificationRead}
            {...notification}
          />
        ))}
      </div>
    </section>
  )
}

interface INotificationListItem extends TNotification {
  handlerOnNotificationRead: (id: number, checked: boolean) => Promise<void>
}

const NotificationListItem = memo(({
  id,
  Creator_User,
  creation_date,
  priority,
  message,
  notification_read,
  handlerOnNotificationRead
}: INotificationListItem) => {

  return (
    <div className='notification-item' >
      <i className='fa-solid fa-user user-icon'></i>
      {priority === PRIORITY.URGENT && <i className='fa-solid fa-triangle-exclamation alert-icon'></i>}
      <div className='notifications-description'>
        <p className='notification-item-user'>
          {Creator_User.name} {Creator_User.lastname}
        </p>
        <p className='notification-item-description'>{
          `${message[0].toLocaleUpperCase()}${message.slice(1)}`.substring(0, 200)
        }</p>
        <p className='notification-item-date'>{
          getTimeElapsed(new Date(creation_date))
        }</p>
      </div>

      <CheckBox
        className='notification-item-read'
        value={notification_read}
        onValueChange={(value) => handlerOnNotificationRead(id, value)}
      />
    </div>
  )
})

interface IModulesMenu {
  visible?: boolean;
}
const ModulesMenu = memo(({ visible = false }: IModulesMenu) => {
  const navigate = useNavigate();
  const { selectedClient, selectedWork, loading, onClickSyncronize, optionsToShow } = useModulesMenu()

  const cards_render = useMemo(() => {
    const cards: ReactElement[] = [];

    if (optionsToShow.bim) cards.push(
      <div
        key={"1"}
        className='module-card'
        onClick={() => navigate(ROUTES_PATH.BIM_MODELS)}
      >
        <IconBIM className='icon' />
        <p className='module-name'>BIM</p>
        <p className='module-description'>BIM Projects</p>
      </div>
    )

    if (optionsToShow.users) cards.push(
      <div
        key={"2"}
        onClick={() => navigate(ROUTES_PATH.ACCESS_MENU)}
        className='module-card'>
        <UserOutlined className='icon' />
        <p className='module-name'>Users</p>
        <p className='module-description'>ERA Users</p>
      </div>)

    if (optionsToShow.catalogs) cards.push(
      <div
        key={"3"}
        onClick={() => navigate(ROUTES_PATH.CATALOGS_MENU)}
        className='module-card'
      >
        <BookOutlined className='icon' />
        <p className='module-name'>Catalogs</p>
        <p className='module-description'>Companies, Projects, Providers</p>
      </div>)

    if (optionsToShow.concepts) cards.push(
      <div
        key={"4"}
        onClick={() => navigate(ROUTES_PATH.CONCEPTS_MENU)}
        className='module-card'
      >
        <TagsOutlined className='icon' />
        <p className='module-name'>Concepts</p>
        <p className='module-description'>Era Concepts and Material Keys</p>
      </div>
    );

    if (optionsToShow.assembly) cards.push(
      <div
        key={"5"}
        className='module-card'
        onClick={() => navigate(ROUTES_PATH.ASSEMBLIES_MENU)}>
        <BranchesOutlined className='icon' />
        <p className='module-name'>Assemblies</p>
        <p className='module-description'>BIM and Proforma</p>
      </div>)

    if (optionsToShow.migrations) cards.push(
      <div
        key={"6"}
        className='module-card'
        onClick={() => navigate(ROUTES_PATH.SYNC_MENU)}>
        <CloudDownloadOutlined className='icon' />
        <p className='module-name'>Migrations</p>
        <p className='module-description'>Catalogs</p>
      </div>)
    return cards;
  }, [optionsToShow, navigate]);

  return (
    <section className='modules-floating-container' style={{ display: (visible && !!cards_render.length) ? 'block' : ' none' }}>
      <div className='modules-list-card'>
        {cards_render.map(card => card)}
        {
          (selectedWork && optionsToShow.sync_quantification) &&
          <div
            className={loading ? 'module-card disabled-card' : 'module-card'}
            onClick={() => onClickSyncronize()}>
            <SyncOutlined className='icon' />
            <p className='module-name'>Quantification</p>
            <p className='module-description'>{loading ? `Synchronizing...` : `${selectedClient?.nombre} - ${selectedWork?.work}`}</p>
          </div>
        }
      </div>
    </section>
  )
})

export default ExtraActionsBox
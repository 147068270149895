export const AUTH_APIS = {
  logIn: "/api/login",
  logOut: '/api/logout',
  getActiveUser: '/api/userData',
  getPermissions: '/api/users/roles/permissions',
} as const;

export const APIS = {
  projectTypes: '/api/projectTypes',
  projects: "/api/obras",
  contractItems: "/api/obras/:id/contracts/:contractId/items",
  addContractItems: '/api/obras/:id/contracts/items/concepts',
  fileOptions: '/api/file-options',
  contracts: '/api/obras/:id/contracts',
  contract: "/api/obras/:projectId/contracts/:contractId",
  concepts: "/api/obras/:id/contracts/items/:contractItemId/concepts",
  currencies: "/api/currencies",
  units: '/api/units',
  assemblyTypesByProject: '/api/obras/:projectId/assembly-types',
  conceptsByAssemblyType: '/api/obras/:projectId/assembly-types/:assemblyTypeId/concepts',
  assemblyTypesByPieces: '/api/obras/:projectId/pieces/assembly-types/grouped',
  assemblyTypeById: '/api/obras/:projectId/assembly-types/:assemblyTypeId',
  assemblyUnitsByProject: '/api/assemblyUnits/projects/:projectId',
  assemblyUnits: '/api/assemblyUnits',
  eraConcepts: '/api/concepts/era',
  clientConcepts: '/api/concepts/clients',
  cities: '/api/catalogs/city',
  notificationsByUser: '/api/notifications/by-user',
  notifications: '/api/notifications',
  permissions: '/api/permissions',
  roles: '/api/roles',
  users: '/api/users',
  permissionsByRole: '/api/roles/:roleId/permissions',
  informationLinks: '/api/information-links'
} as const;

export const APIS_SICICC = {
  SYNC_UNITS: '/api/sicicc/units/sync',
  SYNC_CLASSES: '/api/sicicc/keys/classes/sync',
  SYNC_TYPES: '/api/sicicc/keys/types/sync',
  SYNC_FAMILIES: '/api/sicicc/keys/families/sync',
  SYNC_MATERIALS: '/api/sicicc/materials/sync',
  SYNC_PROVIDERS: '/api/sicicc/providers/sync',
  CREATE_catalogMaterials2: '/api/sicicc/materials/create',
  USERS: '/api/sicicc/users',

} as const;

import { userType } from "interfaces/IUsuario";
import { CLIENTS_ROUTE, IRoute, routes } from "./routes";

export const getAccessRoutes = (user: userType | null): IRoute[] => {
  if (user?.admin || user?.superAdmin) return routes;

  const permissions = new Set<IRoute>();
  permissions.add(CLIENTS_ROUTE);

  user?.role?.permissions?.forEach(permission => {
    const route = routes.find(route => !!route?.name
      ? route.name.toLocaleLowerCase() === permission.name.toLocaleLowerCase()
      : route.path.toLocaleLowerCase() === permission.pathName?.toLocaleLowerCase());
    if (route) permissions.add(route);
  });

  return Array.from(permissions.values());
}
import { useCallback, useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Image,
  message,
} from "antd";
import { Navigate } from "react-router-dom";
import './styles/styles.less';
import { useAuthUser } from "contexts/authContext";

const LogIn = () => {
  const { user, signin, verifyRedirect } = useAuthUser();
  const [uploading, setUploading] = useState(false);

  const onFinish = useCallback(async (values: any) => {
    setUploading(true);
    const loginIn = await signin(values.username, values.password);

    !loginIn && message.error("Incorrect username or password!");
    loginIn && verifyRedirect();
    
    setUploading(false);
  }, [signin, verifyRedirect]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onPrivacyRedirect = (url: string): void => {
    window.open(url);
  }

  if (user && !uploading) {
    return <Navigate to={"/clients"} replace />
  }

  return (
    <div
      className="container-login"
    >
      <Card
        className="card-container-form"
      >
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "2.5rem" }}>
          <Image src={"/ERAPBS.png"} preview={false} className="logo" />
        </div>
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          size="large"
          requiredMark={false}
          className="form"
        >
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "Please enter your username!" },
            ]}
          >
            <Input placeholder="Username" style={{ borderRadius: "6px" }} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please enter your password!" },
            ]}
          >
            <Input.Password placeholder="Password" style={{ borderRadius: "6px" }} autoComplete='current-password' />
          </Form.Item>
          <Form.Item  >
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", borderRadius: "6px" }}
              loading={uploading}
              disabled={uploading}
              className="btn-loging"
            >
              Log In
            </Button>
          </Form.Item>
        </Form>
        <div className="privacy-content">
          <p>By clicking "Log In" you are accepting the</p>
          <p><span onClick={() => onPrivacyRedirect("/policy/Privacy Policy.pdf")}>Privacy Policy</span> and the <span onClick={() => onPrivacyRedirect("/policy/Software License.pdf")}>Software Use License.</span></p>
        </div>
      </Card>
    </div>
  );
};

export default LogIn;

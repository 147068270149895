export interface IPaths {
  name: string;
  redirect?: string;
  children?: IPaths[];
}

export interface IRenamePath {
  originalUrl: string;
  newName: string;
}

export const MENU_PATH_NAME = "Menu" as const;
export const DRAWING_MANAGEMENT = "/DrawingManagement/Index" as const;

export const ThreePaths: IPaths[] = [
  {
    name: "Home",
    redirect: "/clients",
    children: [
      {
        name: "Projects",
        redirect: "/projects",
        children: [
          {
            name: MENU_PATH_NAME,
            redirect: "/home",
            children: [
              {
                name: "View BIM Models",
              },
              {
                name: "Production Orders",
              },
              {
                name: "File management",
                redirect: "/DrawingManagement",
                children: [
                  {
                    name: "Quality Control",
                    redirect: `${DRAWING_MANAGEMENT}/QualityControl`
                  },
                  {
                    name: "Documentation",
                    redirect: `${DRAWING_MANAGEMENT}/Documentation`
                  },
                  {
                    name: "Engineering Drawings",
                    redirect: `${DRAWING_MANAGEMENT}/EngineeringDrawings`
                  },
                  {
                    name: "Meeting Minutes",
                    redirect: `${DRAWING_MANAGEMENT}/MeetingMinutes`
                  },
                  {
                    name: "Shop Drawings",
                    redirect: `${DRAWING_MANAGEMENT}/ShopDrawings`
                  },
                  {
                    name: "Budget",
                    redirect: `${DRAWING_MANAGEMENT}/Budget`
                  },
                  {
                    name: "Construction Schedules",
                    redirect: `${DRAWING_MANAGEMENT}/ConstructionSchedules`
                  },
                  {
                    name: "Reports",
                    redirect: `${DRAWING_MANAGEMENT}/Reports`
                  }
                ]
              },
              {
                name: "Reports",
                redirect: "/projectreports",
                children: [
                  {
                    name: "Pieces per station",
                    redirect: "/projectreports/pieces/project"
                  },
                  {
                    name: "Pieces per route (record)",
                    redirect: "/projectreports/pieces/record"
                  },
                  {
                    name: "Breakdown Cost",
                    redirect: "/reports/pie/chart"
                  },
                  {
                    name: "Projects Report",
                    redirect: "/reports/chart/projects"
                  },
                  {
                    name: "Shipments Report",
                    redirect: "/reports/Shipments"
                  },
                  {
                    name: "Pieces per day report",
                    redirect: "/reports/PiecesPerDay"
                  }
                ]
              }, {
                name: "Gantt",
                redirect: "/gantt",
                children: [
                  {
                    name: "Manual",
                    redirect: "/gantt/manual"
                  },
                  {
                    name: "Automatic",
                    redirect: "/gantt/automatic"
                  }
                ]
              },
              {
                name: "Project management",
                redirect: "/projectmanagement",
                children: [
                  {
                    name: "Contract",
                    redirect: "/projectmanagement/contracts",
                    children: [
                      {
                        name: "Upload",
                        redirect: "/projectmanagement/contracts/upload"
                      },
                      {
                        name: "View",
                        redirect: "/projectmanagement/contracts/view"
                      }
                    ]
                  },
                  {
                    name: "Proforma",
                    redirect: "/projectmanagement/proforma",
                    children: [
                      {
                        name: "Show",
                        redirect: "/projectmanagement/proforma/show"
                      },
                    ]
                  },
                  {
                    name: "Proforma Assembly",
                    redirect: "/projectmanagement/proforma/ensamble",
                    children: [
                      {
                        name: "Proforma Assignment",
                        redirect: "/ProjectManagement/proforma/ensamble/assign"
                      }
                    ]
                  },
                  {
                    name: "Providers agreements",
                    redirect: "/projectmanagement/provider/agreement",
                    children: [
                      {
                        name: "New Agreement",
                        redirect: "/projectmanagement/provider/agreement/create"
                      },
                      {
                        name: "List Agreement",
                        redirect: "/projectmanagement/provider/agreement/list",
                        children: [
                          {
                            name: "Authorize Agreement",
                            redirect: "/projectmanagement/provider/agreement/list/authorize"
                          },
                          {
                            name: "Agreement Record",
                            redirect: "/projectmanagement/provider/agreement/list/record"
                          }
                        ]
                      },
                    ]
                  },
                  {
                    name: "Account statement Providers",
                    redirect: "/projectmanagement/account/statements",
                    children: [
                      {
                        name: "Account statement",
                        redirect: "/projectmanagement/account/statements/show"
                      }
                    ]
                  },
                  {
                    name: "Estimates",
                    redirect: "/projectmanagement/estimates"
                  },
                  {
                    name: "Purchase Order",
                    redirect: "/projectmanagement/purchaseOrder",
                    children: [
                      {
                        name: "Option",
                        redirect: "/projectmanagement/purchaseorder/Option",
                        children: [
                          {
                            name: "Agreement",
                            redirect: "/projectmanagement/purchaseorder/Option/agreements",
                            children: [
                              {
                                name: "New Order",
                                redirect: "/projectmanagement/purchaseorder/Option/Agreements/PurchaseOrder/Form"
                              },
                            ]
                          },
                          {
                            name: "List Order",
                            redirect: "/projectmanagement/purchaseorder/Option/Agreements/PurchaseOrder/List",
                            children: [
                              {
                                name: "Authorize",
                                redirect: "/projectmanagement/purchaseorder/Option/Agreements/PurchaseOrder/List/Authorize",
                              }
                            ]
                          }
                        ]
                      },
                    ]
                  },
                ]
              }
            ]
          }

        ]
      },
    ]
  },
  {
    name: 'Production Panel',
    redirect: '/productionPanel'
  },
  {
    name: 'Concepts',
    redirect: '/concepts',
    children: [
      {
        name: "Era Concepts",
        redirect: '/concepts/era'
      },
      {
        name: 'Keys',
        redirect: '/concepts/keys'
      },
      {
        name: "Assembly Types",
        redirect: '/concepts/assemblyTypes',
        children: [
          {
            name: "Add",
            redirect: '/concepts/assemblyTypes/add'
          },
          {
            name: "Edit",
            redirect: '/concepts/assemblyTypes/*/project/*'
          }
        ]
      }
    ]
  },
  {
    name: "Stations",
    redirect: "/management/stations"
  },
  {
    name: "Access",
    redirect: '/access',
    children: [
      {
        name: 'Users',
        redirect: '/access/users'
      },
      {
        name: "Roles",
        redirect: "/access/roles"
      }
    ]
  },
  {
    name: "Paths",
    redirect: "/processEditor",
    children: [
      {
        name: "Stations per path",
        redirect: "/processEditor/*"
      }
    ]
  },
  {
    name: 'Stations',
    redirect: "/management/stations"
  },
  {
    name: "Reports",
    children: [
      {
        name: "PIECES / KILOGRAMS PER ROUTE AND STATIONS",
        redirect: "/reports/pieces"
      },
      {
        name: "Projects information",
        redirect: "/reports/obras"
      }
    ]
  },
  {
    name: "Assemblies",
    redirect: '/assemblies',
    children: [
      {
        name: "BIM",
        redirect: "/assemblies/bim",
        children: [
          {
            redirect: '/assemblies/bim/add',
            name: 'Add Assembly'
          }
        ]
      },
      {
        name: "Proformas",
        redirect: "/assemblies/proformas"
      }
    ]
  },
  {
    name: "Catalogs",
    redirect: '/catalogs',
    children: [
      {
        name: "Proyects",
        redirect: "/catalogs/projects"
      },
      {
        name: "Companies",
        redirect: "/catalogs/companies"
      },
      {
        name: "Clients Era",
        redirect: "/catalogs/clientsEra"
      },
      {
        name: "Providers",
        redirect: "/catalogs/providers"
      }
    ]
  },
  {
    name: "Operator Profile",
    children: [
      {
        name: "Assigned Profiles",
      }
    ]
  },
  {
    name: "BIM",
    redirect: "/bim",
    children: [
      {
        name: "Upload BIM Model",
        redirect: "/bim/new"
      },
      {
        name: "View BIM Models",
        redirect: "/bim/models"
      },
      {
        name: "Create View BIM",
        redirect: "/bim/view"
      },
      {
        name: "Edit View BIM",
        redirect: "/bim/editView"
      }
    ]
  },
  {
    name: 'Projects Management',
    redirect: '/sicicc',
    children: [
      {
        name: "Projects",
        redirect: "/sicicc/projects",
        children: [
          {
            name: MENU_PATH_NAME,
            redirect: "/sicicc/home",
            children: [
              {
                name: 'Add or Update',
                redirect: "/Sicicc/Contract/Menu",
                children: [
                  {
                    name: "Contract Form",
                    redirect: "/Sicicc/Contract/form",
                  },
                  {
                    name: "Show",
                    redirect: "/Sicicc/Contract/Show",
                    children: [
                      {
                        name: "Review",
                        redirect: "/sicicc/contract/history",
                      },
                      {
                        name: "History",
                        redirect: "/Sicicc/Contract/history/Index",
                      }
                    ]
                  },
                ]
              },
              {
                name: 'Proforma',
                redirect: "/sicicc/Proforma/index",
                children: [
                  {
                    name: "Show",
                    redirect: "/Sicicc/Proforma/Show",
                  },
                  {
                    name: "Change order",
                    redirect: "/sicicc/Proforma/ChangeOrder",
                  },
                  {
                    name: "Proforma record",
                    redirect: "/sicicc/Proforma/Record",
                  },
                  {
                    name: "Show",
                    redirect: "/sicicc/Proforma/Record/Show",
                  }
                ]
              },
              {
                name: 'Subcontratista',
                redirect: "/sicicc/Subcontratista/menu",
                children: [
                  {
                    name: "Agreements",
                    redirect: "/sicicc/Subcontratista/Agreements",
                    children: [
                      {
                        name: "Home",
                        redirect: "/sicicc/Subcontratista/Agreements/Home",
                        children: [
                          {
                            name: "Form",
                            redirect: "/sicicc/Subcontratista/Agreements/form"
                          },
                          {
                            name: "Details",
                            redirect: "/sicicc/Subcontratista/Agreements/Details",
                            children: [
                              {
                                name: "Edit",
                                redirect: "/Sicicc/Subcontratista/Agreements/Edit"
                              },
                              {
                                name: "OrderChange",
                                redirect: "/Sicicc/Subcontratista/Agreements/OrderChange"
                              },
                              {
                                name: "Index",
                                redirect: "/Sicicc/Subcontratista/Agreements/Index"
                              }
                            ]
                          },
                        ]
                      },
                    ]
                  }
                ]
              },
              {
                name: 'Estimation Contract',
                redirect: '/Sicicc/Estimaciones/Index',
                children: [
                  {

                    name: 'Agreements',
                    redirect: '/Sicicc/Estimaciones/Index/Agreement',
                    children: [
                      {
                        name: 'Contracts',
                        redirect: '/Sicicc/Estimaciones/Index/Agreement/contract',
                        children: [
                          {
                            name: 'Estimate',
                            redirect: '/Sicicc/Estimaciones/Index/Agreement/contract/estimate',
                          }, {
                            name: 'New Estimate',
                            redirect: '/Sicicc/Estimaciones/index/Agreement/Contract/Estimate/Form',
                          }

                        ]
                      }
                    ]
                  }
                  // {
                  //   name: "Show",
                  //   redirect: '/Sicicc/Estimaciones/Show',
                  // },
                ]
              },
            ]
          },
        ]
      }
    ]
  },
  {
    name: "Catalogs Sicicc",
    redirect: "/sicicc/catalogs/*",
  },
  {
    name: "Assembly",
    redirect: '/assembly'
  },
  {
    name: 'Synchronization',
    redirect: '/syncERP'
  }
];

export const getPaths = (
  arrayPaths: IPaths[],
  searchPath: string,
  roadPaths: IPaths[],
  getPathFound: (path: IPaths[]) => void
): void => {
  arrayPaths.forEach((path) => {
    const pathRedirect = path.redirect;
    if (pathRedirect && pathRedirect.toLocaleLowerCase() === searchPath.toLocaleLowerCase()) {
      roadPaths.push({ name: path.name, redirect: pathRedirect });
      getPathFound(roadPaths);
      return;
    }
    const paths = [...roadPaths, { name: path.name, redirect: pathRedirect }];
    getPaths(path.children ?? [], searchPath, paths, getPathFound);
  });
};

export const getPathsToRenameMapped = (paths: IRenamePath[]) => {
  return new Map<string, string>(
    paths.map(path => [path.originalUrl.toLocaleLowerCase(), path.newName])
  )
}
import LogIn from 'pages/Login/LogIn'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ReactElement, memo, useMemo } from 'react';
import { PrivateRoute, DefaultNavigate } from './PrivateRoute';
import { useAuthUser } from 'contexts/authContext';
import { getAccessRoutes } from './config';
import App from 'App';

const Navigation = (): ReactElement => {

  const { user } = useAuthUser();

  const accessRoutes = useMemo(() => getAccessRoutes(user), [user]);

  return (
    <Routes>
      <Route path='login' element={<LogIn />} />
      <Route index={false} element={<App />}>
        {
          accessRoutes.map(({ Component, path, items = [] }) =>
          (
            items.length > 0 ? (
              <Route
                path={path}
                caseSensitive={false}
                key={path}
                element={<PrivateRoute Component={Component} />}>
                {
                  items.map((subRoute) => (
                    <Route
                      path={subRoute.path}
                      key={subRoute.path}
                      caseSensitive={false}
                      element={<PrivateRoute Component={subRoute.Component} />} />
                  ))
                }
              </Route>
            ) : (
              < Route
                key={path}
                path={path}
                caseSensitive={false}
                element={<PrivateRoute Component={Component} />} />
            )
          ))
        }
        <Route
          path='*'
          element={
            <PrivateRoute
              Component={DefaultNavigate({ Element: <Navigate to="/clients" replace /> })}
            />}
        />
      </Route >
    </Routes>
  )
}

export default memo(Navigation)
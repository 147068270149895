import { useNavigate } from 'react-router-dom';
import { useClientes } from './hooks/useClientes';
import { LoadPanel } from 'devextreme-react';
import './styles/styles.less';

const URL_IMAGE = process.env.REACT_APP_URL_IMAGE ?? '';

const Clientes = () => {

  const navigate = useNavigate();
  const {
    companys = [],
    loading,
    onHandlerCompany
  } = useClientes();

  return (
    <>
      <LoadPanel visible={loading} message="Loading clients..." />
      <div className='container-options-clients' >
        {
          companys?.map((company) => (
            <div
              className='item'
              onClick={() => onHandlerCompany(company, () => navigate(`/projects`, { state: "clients" }))}
              key={company.idempresa}
            >
              <img className='img-content' alt='Client' src={`${URL_IMAGE}${company.pathImage}`} />
              <h3>{company.nombre.toUpperCase()}</h3>
              <p className='obras'>Active works: {company.obras}</p>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default Clientes
import { useAuth } from "App";
import { UserStatus } from "constants/IUsuario";
import { useAuthUser } from "contexts/authContext";
import { IUseDownloadFiles } from "hooks/useDownloadfile";
import { useBackUpFiles, useUserActions } from "hooks";
import { DownloadFiles } from "components/DownloadFiles";
import { FC, memo } from "react";
import { Button, Popup } from "devextreme-react";
import { Progress } from 'antd';
import ExtraActionsBox from "./ExtraActionsBox";
import "./TopBar.less";

export const TopBar = () => {

  return (
    <>
      <div className="box-container">
        <ExtraActionsBox />
        <UserOptions
          Progress={({ progress }) => <Progress percent={progress} style={{ display: progress > 0 ? 'block' : "none" }} />}
        />
      </div>
    </>
  )
};

interface IUserOptions {
  Progress: FC<{ progress: number }>;
}
const UserOptions = ({ Progress }: IUserOptions) => {

  const {
    handlerShowMenus,
    menusState,
    clearData
  } = useAuth();

  const {
    user,
    signout,
    ready
  } = useAuthUser();

  const { DownloadZip, progress, handlerOpenModal, openModal } = useUserActions();

  const handlerSignOut = async (): Promise<void> => {
    clearData();
    setTimeout(() => signout(), 0);
  }

  return (
    <>
      <div
        className="user-option"
        onClick={() => handlerShowMenus("user")}>
        <span id="user">{user?.username ?? "User"}</span>
        <i
          className="fas fa-chevron-right"
          style={{ transform: `rotate(${menusState.user ? "90deg" : "0deg"})` }}
        >
        </i>
      </div >
      <div className={`box-options ${menusState.user ? "show" : "hidden"}`}>
        <p
          onClick={() => handlerSignOut()}
        >
          <i className={ready === UserStatus.SignOut ? "fas fa-spinner rotate" : "fas fa-sign-out-alt"}></i>
          <span>Sign Out</span>
        </p>
        <p onClick={() => window.open("/policy/Privacy Policy.pdf")}>Privacy Policy</p>
        <p onClick={() => window.open("/policy/Software License.pdf")}>Software Use Licence</p>
        <p onClick={() => handlerOpenModal(true)}>Download backup</p>
        <Progress progress={Math.round(progress)} />
      </div>
      <BackUpModal
        onClose={() => handlerOpenModal(false)}
        visible={openModal}
        DownloadZip={DownloadZip}
      />
    </>
  )
}

interface IBackUpModal extends Pick<IUseDownloadFiles, "DownloadZip"> {
  visible: boolean;
  onClose: VoidFunction;
}

const BackUpModal = memo(({ DownloadZip, onClose, visible }: IBackUpModal) => {

  const { pathFiles, handlerOpenModal, openModal } = useBackUpFiles({ fetching: visible });

  return (
    <Popup
      height={"auto"}
      width={'60%'}
      maxHeight={"80%"}
      maxWidth={"60%"}
      visible={visible}
      showCloseButton={true}
      onHidden={onClose}
      title={"Download Files"}
      dragEnabled={false}
    >
      <Button
        text="Download Backup Zip"
        type='normal'
        width={'50%'}
        stylingMode="outlined"
        onClick={() => DownloadZip(pathFiles)}
      />
      <Button
        text="Download file"
        type='normal'
        width={'50%'}
        stylingMode="outlined"
        onClick={() => handlerOpenModal(true)}
      />
      <DownloadFiles
        pathsFile={pathFiles}
        setBackUpModal={onClose}
        openModal={openModal}
      />
    </Popup>
  )
}, (prev, next) => prev.visible === next.visible)
import axios from 'axios';
import useSWR, { KeyedMutator } from 'swr';

export interface IUseFetchSWR<T = any> {
  data?: T;
  error?: any,
  loading: boolean;
  isValidating?: boolean;
  mutate: KeyedMutator<T>;
}

export const fetcher = (url: string) => axios.get(url, { withCredentials: true }).then(res => res.data);
export const fetcherWithoutCredentials = (url: string) => axios.get(url).then(res => res.data);

export const useFetchSWR = (url: string | null, refresh?: number): IUseFetchSWR<any> => {

  const { data, error, mutate, isLoading } = useSWR(url, fetcher, { revalidateOnReconnect: true, refreshInterval: refresh, keepPreviousData: true });

  return {
    data,
    error,
    loading: isLoading,
    mutate
  }
}

export type IUseGetDataSWR<T> = Readonly<{
  url: string | null;
  fetch?: (url: string) => Promise<T> | (() => Promise<T>);
  refresh?: number,
  keepCache?: boolean;
}>

export const useGetDataSWR = <T = any>({ url, fetch, keepCache = true, refresh = 0 }: IUseGetDataSWR<T>): IUseFetchSWR<T> => {

  const { data, error, mutate, isLoading, isValidating } = useSWR(url, fetch ?? fetcher,
    {
      revalidateOnReconnect: true,
      refreshInterval: refresh,
      keepPreviousData: keepCache,
      errorRetryCount: 3
    });

  return {
    data,
    error,
    loading: isLoading,
    mutate,
    isValidating
  }
}
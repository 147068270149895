import { useState } from "react";
import { useGetDataSWR } from "./useFetchSWR";

type UseSelectState<T, Value> = Readonly<{
  data: T[];
  error: any;
  loading: boolean;
  onSelectedChanged: (value: Value | null) => void;
  selectedId: Value | null;
}>

interface Props {
  url: string;
  fetcher: (url: string) => Promise<any>;
}
export const useSelectState = <T = any, Value = number>({ url, fetcher }: Props): UseSelectState<T, Value> => {

  const [selectedId, setSelectedId] = useState<Value | null>(null);

  const { data = [], error, loading } = useGetDataSWR<T[]>({
    url,
    fetch: fetcher,
    keepCache: true
  });

  const onSelectedChanged = (value: Value | null): void => setSelectedId(value);

  return {
    data,
    error,
    loading,
    onSelectedChanged,
    selectedId
  }
}
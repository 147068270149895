import { useAuthUser } from 'contexts/authContext';
import { LazyExoticComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { JSXElement } from './routes'
interface IProps {
  Component: LazyExoticComponent<JSXElement> | JSXElement;
}
export const PrivateRoute = ({ Component }: IProps) => {
  const { user, wasLoggedOut } = useAuthUser();

  if (!user || wasLoggedOut) return <Navigate to={"/login"} replace />;
  return <Component />;
}

interface IDefaultNavigate {
  Element: ReactElement;
}

export const DefaultNavigate = ({ Element }: IDefaultNavigate): JSXElement => {
  return () => Element;
}
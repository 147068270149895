import { useAuth } from "App";
import { IconHome } from "assets";
import { createContext, ReactElement, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPaths, getPathsToRenameMapped, type IPaths, type IRenamePath, MENU_PATH_NAME, ThreePaths } from "routes/threePaths";
import { FormatFistUpperCase } from "utils/Format";
import '../layout/styles.less';

interface IBreadCrumsContext {
  breadCrums: IPaths[];
  handlerOnRenamePaths: (paths: IRenamePath[]) => void;
}

const BreadCrumsContext = createContext({} as IBreadCrumsContext);
const { Provider } = BreadCrumsContext;

interface Props {
  children?: ReactElement | ReactElement[];
}

const BreadCrumsProvider = ({ children }: Props): ReactElement => {

  const [pathsToRename, setPathsToRename] = useState<IRenamePath[]>([]);

  const { selectedWork } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const pathsToChange = useMemo(() => getPathsToRenameMapped(pathsToRename), [pathsToRename]);

  const currentPath: string = useMemo(() => {
    const state = location.state
      ? typeof location.state === 'number' || typeof location.state === 'object'
        ? '*'
        : location.state
      : '*';
    return location.pathname.replace(/([0-9]+)/g, state)
  }, [location]);

  const breadCrums: IPaths[] = useMemo(() => {
    let roadPaths: IPaths[] = [];
    getPaths(ThreePaths, currentPath, [], (paths) => {
      roadPaths = paths.map((path) =>
        path.name === MENU_PATH_NAME
          ? { ...path, name: selectedWork?.work ?? MENU_PATH_NAME }
          : { ...path, name: pathsToChange.get(path.redirect?.toLocaleLowerCase() ?? '') ?? path.name }
      );
    });
    return roadPaths;
  }, [currentPath, selectedWork, pathsToChange]);

  const handlerOnRenamePaths = (paths: IRenamePath[]): void => {
    setPathsToRename(paths);
  }

  const value: IBreadCrumsContext = {
    breadCrums,
    handlerOnRenamePaths
  }

  return (
    <Provider
      value={value}
    >
      <nav className={`header`} >
        <div className='content-title'>
          <h2 className='title'>
            {
              breadCrums.map((path, index) => (
                <div key={index}
                  style={{ color: (currentPath === path.redirect || !path.redirect) ? "var(--darkgray)" : "var(--teal)" }}
                  className="container-path">
                  {index === 0 && (<img src={IconHome} alt="Home" className='icon' />)}
                  <span
                    className={(path.redirect && currentPath !== path.redirect) ? "path" : "no-path"}
                    onClick={() => path.redirect && navigate(path.redirect, { state: location.state })}
                  >
                    {FormatFistUpperCase(path.name)}
                  </span>
                  <span
                    style={{ margin: "0 .25rem" }}
                  >
                    {index !== (breadCrums.length - 1) && ">"}
                  </span>
                </div>
              ))
            }
          </h2>
        </div>
      </nav>
      {children}
    </Provider>
  )
}

export const useBreadCrums = (): IBreadCrumsContext => useContext(BreadCrumsContext);

export default BreadCrumsProvider;
import { userType } from "interfaces/IUsuario";

type Roles = "RESIDENTE" | "INGENIERIA" | "COMPRAS" | "GERENCIA" | "RECEPCION DE OBRA" | "DIRECCION" | "CONTROL DE OBRA";

type OptionsModulesMenu = {
  bim: boolean;
  users: boolean;
  catalogs: boolean;
  concepts: boolean;
  migrations: boolean;
  assembly: boolean;
  sync_quantification: boolean;
}

export class MenuRolValidationsService {

  private readonly BIM_ACCESS: Roles[] = ["GERENCIA", "INGENIERIA", "DIRECCION"];
  private readonly USERS_ACCESS: Roles[] = ["GERENCIA", "DIRECCION"];
  private readonly CATALOGS_ACCESS: Roles[] = [...this.USERS_ACCESS];
  private readonly MIGRATIONS_ACCESS: Roles[] = [...this.USERS_ACCESS];
  private readonly ASSEMBLY_ACCESS: Roles[] = ["GERENCIA", "INGENIERIA", "DIRECCION", 'CONTROL DE OBRA'];
  private readonly SYNC_ACCESS: Roles[] = ["GERENCIA", "COMPRAS", "DIRECCION"];
  private readonly CONCEPTS_ACCESS: Roles[] = ["GERENCIA", "DIRECCION", "CONTROL DE OBRA"];

  constructor(
    private user: userType | null
  ) { }

  public getOptionsToShowForModulesMenu(): OptionsModulesMenu {
    return {
      bim: this.showOption(this.BIM_ACCESS),
      catalogs: this.showOption(this.CATALOGS_ACCESS),
      concepts: this.showOption(this.CONCEPTS_ACCESS),
      migrations: this.showOption(this.MIGRATIONS_ACCESS),
      assembly: this.showOption(this.ASSEMBLY_ACCESS),
      sync_quantification: this.showOption(this.SYNC_ACCESS),
      users: this.showOption(this.USERS_ACCESS)
    }
  }

  private showOption(roles: Roles[]): boolean {
    if (this.user?.admin || this.user?.superAdmin) return true;
    if (!this.user?.role) return false;
    return roles.includes(this.user.role.name.toLocaleUpperCase() as Roles);
  }

}

type HomeOptions = {
  bim: boolean;
  project_management: boolean;

}

type ProjectManagementOptions = {
  contracts: boolean;
  proformas: boolean;
  assembly_proformas: boolean;
  agreements: boolean;
  account_statement: boolean;
  purchase_order: boolean;
}

type AssembliesOptions = {
  bim: boolean;
  proforma: boolean;
}

export class OptionsRolValidationsService {

  private readonly BIM_ACCESS: Roles[] = ["GERENCIA", "INGENIERIA", "RESIDENTE", "RECEPCION DE OBRA", "DIRECCION"];
  private readonly PROJECT_MANAGEMENT: Roles[] = ["GERENCIA", "DIRECCION", "COMPRAS", "CONTROL DE OBRA"];
  private readonly CONTRACTS: Roles[] = ["DIRECCION", "GERENCIA", "CONTROL DE OBRA"];
  private readonly PROFORMAS: Roles[] = [...this.PROJECT_MANAGEMENT];
  private readonly ASSEMBLY_PROFORMAS: Roles[] = [...this.CONTRACTS];
  private readonly AGREEMENTS: Roles[] = ["GERENCIA", "DIRECCION", "COMPRAS"];
  private readonly ACCOUNT_STATEMENT: Roles[] = [...this.AGREEMENTS];
  private readonly PURCHASE_ORDER: Roles[] = [...this.AGREEMENTS];
  private readonly BIM_ASSEMBLIES: Roles[] = ['DIRECCION', 'GERENCIA', 'INGENIERIA'];
  private readonly PROFORMA_ASSEMBLIES: Roles[] = ['DIRECCION', 'GERENCIA', 'CONTROL DE OBRA']

  constructor(
    private user: userType | null
  ) { }

  getHomeOptions(): HomeOptions {
    return {
      bim: this.showOption(this.BIM_ACCESS),
      project_management: this.showOption(this.PROJECT_MANAGEMENT)
    }
  }

  getProjectManagementOptions(): ProjectManagementOptions {
    return {
      contracts: this.showOption(this.CONTRACTS),
      proformas: this.showOption(this.PROFORMAS),
      assembly_proformas: this.showOption(this.ASSEMBLY_PROFORMAS),
      agreements: this.showOption(this.AGREEMENTS),
      account_statement: this.showOption(this.ACCOUNT_STATEMENT),
      purchase_order: this.showOption(this.PURCHASE_ORDER)
    }
  }

  getAssembliesOptions(): AssembliesOptions {
    return {
      bim: this.showOption(this.BIM_ASSEMBLIES),
      proforma: this.showOption(this.PROFORMA_ASSEMBLIES)
    }
  }

  private showOption(roles: Roles[]): boolean {
    if (this.user?.admin || this.user?.superAdmin) return true;
    if (!this.user?.role) return false;
    return roles.includes(this.user.role.name.toLocaleUpperCase() as Roles);
  }

}


import { useNavigate } from 'react-router-dom';
import { useWorks } from './hooks/useWorks';
import { LoadPanel } from 'devextreme-react';
import SubHeader from 'layout/SubHeader';
import './styles/styles.less';

const Proyects = () => {
  const { loading, onRedirect, works, selectedClient } = useWorks();
  const navigate = useNavigate();

  return (
    <div className='container-works'>
      <SubHeader title='Available Projects' subTitle={selectedClient?.nombre} />
      <LoadPanel visible={loading} message="Loading projects..." />
      <div className='list-works'>
        {
          works?.map((work) => (
            <div className='work' onClick={() => onRedirect(work, () => navigate(`/home`, { state: "proyects" }))} key={work.idObra}>
              <h2>{work.nombreObra}</h2>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Proyects
import { IMenusApp } from 'interfaces/IUsuario';
export const KEY_STORAGE_CLIENT: string = "KEY_CLIENT";
export const KEY_STORAGE_WORK: string = "KEY_WORK";
export const KEY_STORAGE_CODE: string = "CODE_JOIST"

export const DEFAULT_MENU_STATES: IMenusApp = {
  options: false,
  user: false
}

export const enum UserStatus {
  Stop = 0,
  SignOut = 1,
  SignIn = 2,
  GetSession = 3,
  Saving = 4,
  Updating = 5,
  Deleting = 6
}

export const enum ROLES_APIS {
  ROLES_API = '/api/roles',
  PERMISSIONS_API = '/api/permissions',
  STATIONS_API = '/api/stations',
  USER_API = '/api/users'
}

export const REDIRECT: string = "?from=";

import {
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import { TopBar } from "./layout/TopBar";
import { Layout, Image } from "antd";
import { PageLoader } from "./components/PageLoader";
import { ISelectedWork, IEmpresas, SelectOption, IMenusApp, MenuApp } from "interfaces/IUsuario";
import BreadCrums from "layout/BreadCrums";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { DEFAULT_MENU_STATES, KEY_STORAGE_CLIENT, KEY_STORAGE_WORK, UserStatus } from 'constants/IUsuario';
import { IconHome } from 'assets/index';
import { SWRConfig } from "swr";
import { ROUTES_PATH } from "routes/routes";
import { useApp } from "hooks/useApp";
import "./App.less";
import 'react-toastify/dist/ReactToastify.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import 'devexpress-gantt/dist/dx-gantt.css';
import BreadCrumsProvider from "contexts/BreadCrumsContext";


interface IContextAuth {
  selectedWork?: ISelectedWork | null;
  selectedClient?: IEmpresas | null;
  onAssignOption: (select: ISelectedWork | IEmpresas, option: SelectOption) => void;
  handlerShowMenus: (menu: MenuApp, defaultValue?: boolean) => void;
  menusState: IMenusApp;
  clearData: () => void;
  modeMobile: boolean;
  synchronizeQuantification: () => void;
}

export const AuthContext = createContext({} as IContextAuth);
const { Provider } = AuthContext;

function App() {
  const {
    menusState,
    modeMobile,
    mounted,
    selectedClient,
    selectedWork,
    ready,
    user,
    setMenuState,
    setModeMobile,
    setSelectedClient,
    setSelectedWork,
    synchronizeQuantification
  } = useApp();

  const navigate = useNavigate();

  const onAssignOption = useCallback((select: ISelectedWork | IEmpresas, option: SelectOption): void => {
    if (option === "Client") {
      localStorage.setItem(KEY_STORAGE_CLIENT, JSON.stringify(select));
      setSelectedClient(select as IEmpresas);
      return
    }
    localStorage.setItem(KEY_STORAGE_WORK, JSON.stringify(select));
    setSelectedWork(select as ISelectedWork);
  }, [setSelectedClient, setSelectedWork]);

  const handlerShowMenus = (menu: MenuApp, defaultValue?: boolean): void => {
    const showMenu: boolean = defaultValue ?? !menusState[menu];
    if (showMenu === menusState[menu]) return
    setMenuState({ ...menusState, [menu]: showMenu });
  }

  const assignMobileMenu = useCallback((): void => {
    const screenX: number = window.screen.width;
    setModeMobile(screenX < 500);
  }, [setModeMobile]);

  const clearData = useCallback((): void => {
    setSelectedClient(null);
    setSelectedWork(null);
  }, [setSelectedClient, setSelectedWork]);

  /**GET STORAGED PROJECT AND CLIENT */
  useEffect(() => {
    const getStorageData = (): void => {
      if (localStorage.getItem(KEY_STORAGE_CLIENT)) {
        const storagedClient: IEmpresas = JSON.parse(localStorage.getItem(KEY_STORAGE_CLIENT)!);
        setSelectedClient(storagedClient);
      }
      if (localStorage.getItem(KEY_STORAGE_WORK)) {
        const storagedWork: ISelectedWork = JSON.parse(localStorage.getItem(KEY_STORAGE_WORK)!);
        setSelectedWork(storagedWork);
      }
    }
    getStorageData();
  }, [setSelectedClient, setSelectedWork]);

  /*DETECTE WINDOWS SIZE (MOBILE) */
  useEffect(() => {

    if (!mounted.current) {
      assignMobileMenu();
      mounted.current = true;
    }

    window.addEventListener("resize", assignMobileMenu);
    return () => {
      window.removeEventListener("resize", assignMobileMenu);
    }
  }, [mounted, assignMobileMenu]);

  /*DISABLED RIGHT CLICK */
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return
    window.addEventListener("contextmenu", (e) => e.preventDefault(), false);
    return () => {
      window.removeEventListener("contextmenu", (e) => e.preventDefault(), false);
    }
  });

  if (ready === UserStatus.GetSession) {
    return <PageLoader message="Getting session" showLogos={false} />;
  }

  if (!user) {
    return <Navigate to={"/login"} replace />
  }

  return (
    <Provider value={{ selectedWork, selectedClient, onAssignOption, menusState, handlerShowMenus, clearData, modeMobile, synchronizeQuantification }}>
      <SWRConfig >
        <Layout>
          {user && (
            <>
              <Layout.Header
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "0 0 35px 20px",
                  height: '64px',
                  borderBottom: "1px solid #f0f0f0"
                }}
              >
                <div
                  style={{
                    float: "left",
                    height: "100%",
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <Image
                    height={30}
                    width={87}
                    src={"/ERAPBS.png"}
                    preview={false}
                    onClick={() => navigate(selectedWork ? ROUTES_PATH.HOME : ROUTES_PATH.CLIENTS)}
                  />
                </div>
                <TopBar />
              </Layout.Header>
            </>
          )}
          <Layout.Content
            style={{
              backgroundColor: "var(--gray5)",
              width: '100%',
              minHeight: 'calc(100dvh - 64px)',
              position: "absolute",
              top: user ? '64px' : 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "auto",
              padding: "0 1.5rem 1rem 1.5rem" /*1rem = 16px */
            }}
            onMouseOver={() => setMenuState(DEFAULT_MENU_STATES)}
          >
            <BreadCrumsProvider>
              {/* <BreadCrums icon={IconHome} /> */}
              <ToastContainer position="top-right" />
              <Outlet />
            </BreadCrumsProvider>
          </Layout.Content>
        </Layout>
      </SWRConfig>
    </Provider>
  );
}
export const useAuth = (): IContextAuth => {
  return useContext(AuthContext);
}

export default App;

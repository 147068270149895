export const FormatTitleSerie = (value: string): string => {
    const values: string[] = value.split("_");
    return values.join(" ");
}

export const groupKeyByOrder = (xs: any[], key: string, orderBy: string): any[] => {
    const dataGrouped = xs.reduce(function (rv, x) {
        (rv[x[orderBy]] = rv[x[orderBy]] || []).push(x);
        return rv;
    }, {});
    const keysGrouped: string[] = Object.keys(dataGrouped);
    const keysOrder: any[] = [];
    let maxLength: number = 0;
    keysGrouped.forEach((keyGrouped) => {
        const array: any[] = dataGrouped[keyGrouped];
        maxLength = Math.max(maxLength, array.length);
    });

    for (let i = 0; i < maxLength; i++) {
        keysGrouped.forEach((keyGrouped) => {
            const element = dataGrouped[keyGrouped][i];
            if (element) {
                const alreadyInArray = keysOrder.find((item) => item[key] === element[key]);
                if (!alreadyInArray) {
                    const arrayFiltrado = xs.filter((item) => item[key] === element[key]);
                    arrayFiltrado.forEach((item) => {
                        keysOrder.push(item);
                    })
                }
            }
        })
    }

    return keysOrder;
}

export const FormatFistUpperCase = (value: string): string => {
    const strings: string[] = value.split(" ");
    let joinString: string = "";
    strings.forEach((string) => {
        joinString += `${string.slice(0, 1).toUpperCase()}${string.slice(
            1,
            string.length
        ).toLocaleLowerCase()} `;
    });
    return joinString;
};

export const FormatCurrencyNumber = (value: number, decimals: number = 0): string => {
    return new Intl.NumberFormat("en-US", { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(value);
}

export const truncateNumber = (value: number, decimals: number): number => {
    return Math.trunc(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}
interface IFormatNumber {
    value: number;
    inString?: boolean;
    decimals?: number;
  }
export const formatNumber = <T extends number | string>({ value, decimals = 2, inString = false }: IFormatNumber): T => {
    const formattedNumber = Intl.NumberFormat('en-US', { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(value);
    return inString
      ? formattedNumber as T
      : Number(formattedNumber.replace(/[,]/g, '')) as T;
  }

import { useAuth } from "App"
import { IResponse, asyncRequest } from "api/fetch"
import { useAuthUser } from "contexts/authContext"
import { MenuRolValidationsService } from "services/Rol_Validations"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { showErrorsOnEntities } from "utils/HTTPMessages"

export const useModulesMenu = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const { selectedWork, selectedClient } = useAuth();
    const { user } = useAuthUser();

    const onClickSyncronize = async () => {
        setLoading(true)
        const toastId = toast.loading("Synchronizing...");

        const response: IResponse = await asyncRequest({ url: `/api/obras/${selectedWork?.id}/pieces/assembly-types/proforma-quantification/sync`, data: {}, method: 'PUT', withCredentials: true })
        if (!response.isOk) {
            toast.update(toastId, { style: { display: "none" } });
            showErrorsOnEntities(response.error, response.status)
            setLoading(false)
            return
        }

        toast.update(toastId, {
            render: "Synchronized",
            type: "success",
            isLoading: false,
            autoClose: 3000
        });

        setLoading(false)
    }

    const optionsToShow = useMemo(() => new MenuRolValidationsService(user)
        .getOptionsToShowForModulesMenu(), [user]);

    return {
        loading,
        selectedClient,
        selectedWork,
        onClickSyncronize,
        optionsToShow
    }
}
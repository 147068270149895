import { Image, Space } from "antd";
import "./PageLoader.less";

export const PageLoader = ({
  message,
  showLogos,
}: {
  message: string;
  showLogos: boolean;
}) => {
  return (
    <div
      className="pageLoader"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "top",
        flexDirection: "column",
      }}
    >
      <div style={{ height: "100px" }}></div>
      {showLogos ? (
        <Space>
          <Image height={55} width={140} src={"/ERAPBS.png"} preview={false} />
        </Space>
      ) : null}
      <div style={{ marginBottom: "10px", fontSize: "30px" }}>{message}</div>
      <div className="loader">
        <div className="inner one"></div>
        <div className="inner two"></div>
        <div className="inner three"></div>
      </div>
    </div>
  );
};

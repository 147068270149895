import { useState, useEffect, useMemo } from 'react';
import { useAuth } from "App";
import axios from 'axios';
import { HTTP_MESSAGES } from 'utils/HTTPMessages';
import { message } from "antd";

interface IWorks {
  idObra: number;
  nombreObra: string;
  empresa: string;
}

export const useWorks = () => {
  const { onAssignOption, selectedClient } = useAuth();
  const [works, setWorks] = useState<IWorks[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const onRedirect = (work: IWorks, callback: VoidFunction): void => {
    onAssignOption({ id: work.idObra, work: work.nombreObra }, "Work");
    callback();
  }

  const orderedProjects = useMemo(() => {
    return works?.sort((a, b) => {
      if (a.nombreObra === "BERROS" || a.nombreObra === "FAIRHOPE") {
        return -1;
      }
      return 1;
    })
  }, [works]);

  useEffect(() => {
    if (!selectedClient) return;
    let cancel = false;
    const getWorks = async (): Promise<void> => {
      setLoading(true);
      axios.get(`${window.location.origin}/api/obras/${selectedClient?.idempresa}/obras`)
        .then((response) => {
          if (cancel) return;
          const dataWorks: IWorks[] = response.data;
          dataWorks.length === 0 && message.info("No hay proyectos registrados");
          setWorks(dataWorks)
        })
        .catch((err) => {
          if (cancel) return;
          const errorMessage: string = HTTP_MESSAGES[err.request.status as number];
          message.error(errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    getWorks();
    return () => {
      cancel = true;
    };
  }, [selectedClient]);

  return {
    works: orderedProjects,
    loading,
    onRedirect,
    selectedClient
  }
}